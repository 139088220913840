@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");

.card {
  position: relative;
  width: 320px;
  height: 500px;
  background: #191919;
  border-radius: 20px;
  overflow: hidden;
}

.card::before {
  content: "";
  position: absolute;
  top: -50%;
  width: 100%;
  height: 100%;
  /* background: #ffce00; */
  background: #e3611a;
  /* background: #25346a; */
  transform: skewY(345deg);
  transition: 0.5s;
}

.card:hover::before {
  top: -70%;
  transform: skewY(390deg);
}

.card::after {
  /* content: ""; */
  position: absolute;
  bottom: 0;
  left: 0;
  font-weight: 600;
  font-size: 6em;
  color: rgba(0, 0, 0, 0.1);
}

.card .imgBox {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  z-index: 1;
}
.card .contentBox {
  position: relative;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2;
}
.card .contentBox h3 {
  font-size: 18px;
  color: white;
  font-weight: 500;
  font-family: poppins sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.card .contentBox .details {
  position: relative;
  top: 100px;
  opacity: 0;
  padding: 10px 30px;
  margin-top: 15px;
  color: #000000;
  text-decoration: none;
  background: #ffce00;
  border-radius: 30px;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: 0.5s;
}
.card:hover .contentBox .details {
  top: 0;
  opacity: 1;
}

.products-img {
  height: 300px;
  width: auto;
}

.card .contentBox p {
  font-size: 14px;
  color: white;
  font-weight: 500;
  font-family: poppins sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
}
