@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200&display=swap");
@font-face {
  font-family: "Amsterdam";
  src: url("../fonts/Amsterdam.ttf");
}

.last-title {
  font-family: "Amsterdam" !important;
  font-size: 26px !important;
  font-weight: 300 !important;
  line-height: 40px !important;
  /* margin-bottom: 20px !important; */
}

body {
  margin: 0px !important;
  padding: 0px !important;
  box-sizing: border-box !important;
  overflow-x: hidden !important;
  /* background: #f8f8f8 !important; */
  background: #ffffff !important;
}

/* scrool start */

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1eeeb;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #e3611a;
  border-radius: 10px;
}

/* scrool end */

/* header start */
/* .navbar .nav-logo {
  float: left !important;
  margin-right: 20px;
  box-shadow: 0px 2px 8px #2a3a6e;
  border-radius: 8px;
} */

.navbar .nav-logo {
  font-size: 24px;
  font-weight: 600;
  color: #2a3a6e;
  line-height: 24px;
  margin-right: auto;
  margin-left: 0;
  cursor: pointer;
}

.navbar-expand {
  justify-content: end !important;
}

.navbar {
  box-shadow: 0px 2px 8px #2a3a6e;
  background: #f1eeeb !important;
  padding: 16px !important;
  position: fixed !important;
  width: 100% !important;
  top: 0 !important;
  z-index: 9999 !important;
}
.navbar .me-auto {
  font-weight: 600;
}
.nav-link:active {
  color: #e3611a !important;
  font-size: 24px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
}
.navbar .home-link {
  font-size: 24px;
  font-weight: 400;
}
.navbar a {
  font-weight: 400;
  line-height: 24px !important;
  color: #2a3a6e !important;
  text-decoration: none;
  padding: 20px 10px;
  font-size: 20px;
  font-family: poppins sans-serif;
}
/* .navbar a:hover {
  border-bottom: 1px solid #e3611a;
} */
.navbar .home-link-active {
  /* color: #e3611a !important; */
  border-bottom: 2px solid #e3611a;
  /* font-size: 24px; */
  font-weight: 400;
  line-height: 24px;
  font-size: 24px;
}
.navbar .navbar-active {
  /* color: #e3611a !important; */
  border-bottom: 2px solid #e3611a;
}
/* header end */

/* slider start */

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
}
/* slider end */

/* footer start */

.footer {
  background-color: #25346a;
  padding: 30px 0;
}

.footer h4 {
  margin-bottom: 20px;
  color: #fff;
  font-size: 28px;
  font-family: poppins sans-serif;
  line-height: 24px;
  font-weight: 700;
}

.footer ul {
  list-style: none;
  padding: 0;
  font-size: 18px;
  font-weight: 400;
  font-family: poppins sans-serif;
  line-height: 24px;
}

.footer ul li {
  margin-bottom: 10px;
  color: #fff;
  font-family: poppins sans-serif;
  line-height: 24px;
  font-size: 18px;
  font-weight: 400;
}
.footer ul li a:hover {
  color: #e3611a;
}
.footer ul li a {
  color: #fff;
  text-decoration: none;
}

.footer p {
  margin-top: 24px;
  /* text-align: center; */
  color: #fff;
  font-size: 14px;
  font-weight: 200;
  font-family: poppins;
  line-height: 24px;
}

/* footer end */

/* home page start*/
.home-container-body {
  max-width: 80%;
  padding-left: 15%;
}

.home-container-body .about-title {
  font-size: 34px;
  font-weight: 600;
  line-height: 48px;
  margin-bottom: 20px;
  font-family: poppins sans-serif;
}

.about_section .row {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.about_section .detail-box p {
  margin-top: 15px;
  font-family: Poppins sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
}

.about_section .img-box img {
  width: 50%;
  margin-top: 8px !important;
  box-shadow: 15px 10px #888888;
}
/* 
.about_section h1::before {
  content: "";
  position: absolute;
  top: 80px;
  left: 0;
  width: 92%;
  height: 600px;
  background: #e3611a;
  z-index: -1;
  transform: skewX(-20deg);
  transform-origin: left;
} */
.about_section .abaout-span {
  font-size: 34px;
  font-weight: 700;
  line-height: 48px;
  margin-bottom: 20px;
  font-family: poppins sans-serif;
  display: block;
}

/* home page end*/

/* button model start */

.button-model {
  /* background: #25346a !important; */
  background: #252525 !important;
  border-radius: 1rem !important;
  /* color: #e3611a !important; */
  padding: 10px 0px !important;
  border: none !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  width: 100%;
  height: 100% !important;
  max-width: 230px;
  border: #25346a 1px solid !important;
  font-family: poppins sans-serif !important;
  /* max-width: fit-content; */
}

.button-model:hover {
  background: #ffffff !important;
  color: #252525 !important;
}
/* button model end */

/* products page start*/

.products-header {
  margin-top: 100px;
  width: 100%;
  height: 450px;
  background: #e3611a;
  border-bottom-right-radius: 15rem;
}

.products-header .title {
  font-size: 100px;
  font-weight: 900;
  color: #fff;
  text-align: center;
  padding-top: 150px;
  font-family: poppins sans-serif;
}
.products-header .sub-title {
  font-size: 36px;
  font-weight: 900;
  color: #fff;
  text-align: center;
  padding-top: 10px;
  font-family: poppins sans-serif;
}
.products-header .title-three {
  font-size: 28px;
  font-weight: 900;
  color: #fff;
  text-align: center;
  padding-top: 10px;
  font-family: poppins sans-serif;
}
/* prodcuts page end*/

/* about page start */

.about-body-container .description {
  font-family: poppins sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  margin-top: 20px;
}

.about-body-container .about-title {
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  margin-bottom: 20px;
  font-family: poppins sans-serif;
}

/* about page end */
/* contact page start */
.contact-page-body .title {
  font-size: 28px;
  font-family: poppins sans-serif;
  line-height: 24px;
  font-weight: 700;
  color: #2a3a6e;
  margin-top: 12px;
}
.contact-page-body .content {
  font-size: 18px;
  font-family: poppins sans-serif;
  line-height: 24px;
  font-weight: 600;
  color: #2a3a6e;
  margin-top: 12px;
}
.contact-page-body .contact-content-area {
  height: 70%;
  flex-direction: column;
  justify-content: center;
  display: flex;
  margin-top: 50px;
}

/* contact page end */
.slider-area {
  margin-top: 86px;
  width: 100%;
  padding: 35px;
  height: 568px;
  background: #e3611a;
  border-bottom-right-radius: 15rem;
  border-top-left-radius: 14rem;
}

.scl-img-area .scl-img {
  position: fixed;
  bottom: 26px;
  right: 16px;
  box-shadow: 0px 2px 8px #2a3a6e;
  border-radius: 8px;
}
