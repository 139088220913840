.input-box {
  padding: 10px;
  border: none;
  border-bottom: 2px solid #ffe9c3;
  font-size: 18px;
  outline: none;
  transition: border-bottom-color 0.3s;
  font-family: poppins sans-serif;
  line-height: 24px;
  font-style: 400;
  width: 100%;
  margin-top: 15px;
}

.input-box::placeholder {
  color: #aaa;
  transition: all 0.3s;
  font-family: poppins sans-serif;
  line-height: 24px;
  font-style: 400;
}

.input-box:focus::placeholder {
  transform: translateY(-16px);
  font-size: 14px;
  color: #555;
}

.input-box:focus {
  border-bottom-color: #1f6feb;
  transition: box- 0.1s ease-in-out;
}
