.card-body {
  padding: 0;
  box-shadow: 14px 22px 22px gray;
  border-radius: 13px;
  background: #25346a;
  margin-bottom: 50px;
  margin-top: 15px;
  margin-bottom: 25px;
  /* position: relative; */
}

.card-body .card-img {
  width: 100% !important;
  height: 550px !important;
  padding: 8px;
  border-radius: 20px !important;
  object-fit: contain;
  margin: 6px 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.card-body .card-title {
  font-size: 30px;
  font-family: poppins sans-serif;
  font-weight: 600;
  color: #fff;
}
.card-body p {
  font-size: 18px;
  font-family: poppins sans-serif;
  font-weight: 500;
  color: #fff;
}
