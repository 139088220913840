@media screen and (max-width: 768px) {
  /* home page start */
  .navbar .nav-logo {
    display: none;
  }
  .slider-area {
    height: 424px;
    border-top-left-radius: 9rem;
    padding: 25px;
  }
  .about_section h1::before {
    width: 88%;
    height: 243px;
    top: 80px;
  }
  .navbar .home-link {
    font-size: 17px;
  }
  .navbar .home-link-active {
    font-size: 17px;
  }
  .navbar a {
    font-size: 17px;
    padding: 5;
  }
  .navbar .nav-logo {
    margin-right: 0;
  }
  .navbar {
    padding: 8px !important;
    max-width: 100% !important;
  }
  .navbar-expand {
    justify-content: center !important;
  }
  .res-padding {
    padding: 8px 0 !important;
    display: flex;
    justify-content: center;
  }
  .about_section .img-box img {
    width: 100%;
    margin-top: 34px !important;
  }
  /* home page end */
  /* products page start */
  .products-header {
    height: 660px;
  }
  .products-header .title {
    font-size: 60px;
  }
  .products-header .sub-title {
    font-size: 26px;
  }
  .products-header .title-three {
    font-size: 22px;
  }
  /* products page end */

  /* about  page start */
  .about-banner {
    margin-top: 70px !important;
  }
  /* about  page end */

  .footer ul li {
    font-size: 15px;
  }
  .card {
    width: auto;
  }
}
@media screen and (max-width: 1366px) and (min-width: 1200px) {
  .about_section h1::before {
    width: 90%;
    height: 516px !important;
    top: 0px;
  }
  .card {
    width: auto !important;
  }
}

@media screen and (max-width: 1200px) and (min-width: 767px) {
  .about_section h1::before {
    width: 90%;
    height: 470px !important;
  }
  .card {
    width: auto !important;
  }
}
@media screen and (max-width: 1366px) {
  .slider-area {
    height: 463px !important;
  }
}
