.social-box {
  background: #2a3a6e;
  border-radius: 13px;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 14px 0px;
  margin-bottom: 44px;
}

.social-box .title {
  font-size: 28px;
  font-family: poppins sans-serif;
  line-height: 24px;
  font-weight: 500;
  color: #fff;
  margin-top: 12px;
}
.social-box .content {
  font-size: 16px;
  font-family: poppins sans-serif;
  line-height: 24px;
  font-weight: 400;
  color: #fff;
  margin-top: 12px;
}

.social-box .location {
  cursor: pointer;
}
